<template>
  <div :class="`feed-list-block ${isGridMode ? '__grid' : isPlus ? '__plus' : ''}`">
    <div v-if="isGridMode">
      <CreatorPostGroup :post="post" :index="index" :settings="settings" />
    </div>
    <div v-else>
      <CreatorPostCard :post="post" :index="index" :settings="settings" :mode="$route.query.feed_mode" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["post", "index", "settings"],
  components: {
    CreatorPostCard: () => import("../creator/CreatorPostCard.vue"),
    CreatorPostGroup: () => import("../creator/CreatorPostGroup.vue"),
  },
  computed: {
    isGridMode: function() {
      const isGrid = this.$route.query.feed_mode === "grid";
      return isGrid;
    },
    isPlus: function() {
      const isGrid = this.$route.query.feed_mode === "plus";
      return isGrid;
    },
  },
};
</script>
